import React from "react";
import Modal, { ModalProps } from "~/components/Modal";
import { useApiClient } from "~/hooks/apiContext";
import { Student } from "~/backendApi/model";
import StudentForm, { StudentFormValues } from "./StudentForm";

type EditStudentModalProps = Pick<ModalProps, "onRequestClose"> & {
  readonly student?: Pick<
    Student,
    "id" | "username" | "firstName" | "monthOfBirth" | "yearOfBirth"
  >;
  readonly onComplete: () => void;
};

function EditStudentModal({
  onComplete,
  student,
  onRequestClose,
}: EditStudentModalProps) {
  const apiClient = useApiClient();
  const onSubmit = async ({ birthdayMonth, nickname }: StudentFormValues) => {
    if (!student) {
      throw new Error("Invalid state");
    }
    await apiClient.editStudent(student.id, {
      firstName: nickname,
      monthOfBirth: birthdayMonth.monthName,
      yearOfBirth: birthdayMonth.year,
    });
    onComplete();
  };

  return (
    <Modal
      title="Edit Student"
      size="lg"
      isOpen={!!student}
      onRequestClose={onRequestClose}
    >
      {student && <StudentForm student={student} onSubmit={onSubmit} />}
    </Modal>
  );
}

export default EditStudentModal;
