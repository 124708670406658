import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ApiClient } from "~/backendApi/index";
import { useApiClient } from "./apiContext";

function useApiQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  call: (api: ApiClient) => Promise<TData>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "initialData"
  >,
) {
  const apiClient = useApiClient();
  return useQuery(queryKey, () => call(apiClient), options as any);
}

export default useApiQuery;
