import React from "react";
import Notification from "./Notification";

type ErrorMessagesProps = {
  readonly errors: ReadonlyArray<undefined | string | unknown>;
};

function ErrorMessages({ errors }: ErrorMessagesProps) {
  const messages = errors
    .filter((m) => !!m)
    .map((e: any) => {
      if (typeof e === "string") {
        return e;
      }
      if ("message" in e) {
        return e.message;
      }
      if ("toString" in e) {
        return e.toString();
      }
      return String(e);
    });
  if (messages.length === 0) {
    return null;
  }
  return (
    <Notification type="error" title="Error!">
      {messages.length === 1 ? (
        messages[0]
      ) : (
        <ul>
          {messages.map((m) => (
            <li key={m}>{m}</li>
          ))}
        </ul>
      )}
    </Notification>
  );
}

export type { ErrorMessagesProps };
export default ErrorMessages;
