import React from "react";
import { RouteComponentProps } from "@reach/router";
import useApiQuery from "~/hooks/useApiQuery";
import BaseViewStudent from "../BaseViewStudent";

type ViewGoogleStudentProps = RouteComponentProps<{
  readonly classId: string;
  readonly id: string;
}>;

function ViewGoogleStudent({ classId, id }: ViewGoogleStudentProps) {
  const { error: cError, data: cData } = useApiQuery(
    ["googleCourses", { classId }],
    (apiClient) => apiClient.getGoogleCourse(classId!),
    {
      staleTime: Infinity,
    },
  );

  return (
    <BaseViewStudent
      gameTimesQueryKey={["student", "times", { id }]}
      gameTimesApiCall={(apiClient) => apiClient.getStudentGameTimes(id!)}
      queryKey={["student", { id: id! }]}
      apiCall={(a) => a.getStudent(id!)}
      breadcrumbItems={[
        { to: "/dashboard/", label: "Classes" },
        {
          to: `/dashboard/classes/${classId}/students/`,
          label: `${cData?.name ?? "Class"} students`,
        },
      ]}
      extraErrors={[cError]}
    />
  );
}

export default ViewGoogleStudent;
