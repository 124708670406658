import React, { useEffect, useState } from "react";
import Modal, { ModalProps } from "~/components/Modal";
import Button from "~/components/Button";
import ErrorMessages from "~/components/ErrorMessages";
import { useApiClient } from "~/hooks/apiContext";
import { Student } from "~/backendApi/model";

type ConfirmDeleteModalProps = Pick<ModalProps, "onRequestClose"> & {
  readonly student?: Pick<Student, "id" | "username">;
  readonly onComplete: () => void;
};

function ConfirmDeleteModal({
  student,
  onRequestClose,
  onComplete,
}: ConfirmDeleteModalProps) {
  const apiClient = useApiClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const onConfirmClick = async () => {
    if (!student) {
      throw new Error("Invalid state");
    }

    setError(undefined);
    setLoading(true);
    try {
      await apiClient.deleteStudent(student.id);
      onComplete();
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  const isOpen = !!student;
  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
      setError(undefined);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Are you sure?"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      disabled={loading}
    >
      <ErrorMessages errors={[error]} />
      <p className="text-center">
        Are you sure you want to delete {student?.username}?
      </p>
      <div className="modal-footer-buttons">
        <Button
          type="button"
          colour="blue"
          variant="outline"
          size="small"
          onClick={onRequestClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type="button"
          colour="blue"
          variant="solid"
          size="small"
          onClick={onConfirmClick}
          disabled={loading}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmDeleteModal;
