import React, { CSSProperties, ReactNode } from "react";
import clsx from "clsx";
import "./Row.css";

type RowProps = {
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly children: ReactNode | ReadonlyArray<ReactNode>;
};

function Row({ className, style, children }: RowProps) {
  return (
    <div className={clsx("row-comp", className)} style={style}>
      {children}
    </div>
  );
}

export default Row;
