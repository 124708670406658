import React from "react";
import { sum } from "lodash";
import { formatSecondsToTimeSeconds } from "~/utils/formatSecondsToTime";
import { ActivityTime } from "~/backendApi/gameTimes";

type ActivityProgressProps = {
  readonly times: ActivityTime;
};

function ActivityProgress({
  times: { completeTimes, incompleteTimes },
}: ActivityProgressProps) {
  return (
    <>
      Completed: {completeTimes.length > 0 ? "✅" : "❌"}
      <br />
      Number of attempts: {completeTimes.length + incompleteTimes.length}
      <br />
      Time spent:{" "}
      {formatSecondsToTimeSeconds(sum([...completeTimes, ...incompleteTimes]))}
    </>
  );
}

export default ActivityProgress;
