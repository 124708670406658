import React from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import { useGoogleSsoClientId } from "~/hooks/authContext";
import googleSignInImage from "~/images/signin-google.png";

function GoogleSsoButton() {
  const { origin } = useLocation();

  const redirectUri = `${origin}/oauth/google/`;
  const googleClientId = useGoogleSsoClientId();
  const params = new URLSearchParams();
  params.set("access_type", "online");
  params.set("response_type", "code");
  params.set("redirect_uri", redirectUri);
  params.set("client_id", googleClientId);
  params.set(
    "scope",
    [
      "https://www.googleapis.com/auth/classroom.courses.readonly",
      "https://www.googleapis.com/auth/classroom.rosters.readonly",
    ].join(" "),
  );
  const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;

  return (
    <a href={googleUrl}>
      <img
        src={googleSignInImage}
        width={191}
        height={46}
        alt="Connect via Google"
      />
    </a>
  );
}

export default GoogleSsoButton;
