import React from "react";
import viewIcon from "~/images/view.svg";
import viewOffIcon from "~/images/view-off.svg";
import "./ViewIconButton.css";

type ViewIconButtonProps = {
  readonly visible: boolean;
  readonly onToggle: (newVisible: boolean) => void;
};

function ViewIconButton({ visible, onToggle }: ViewIconButtonProps) {
  return (
    <button
      type="button"
      className="view-icon-button"
      onClick={() => onToggle(!visible)}
    >
      <img src={visible ? viewOffIcon : viewIcon} alt="See password" />
    </button>
  );
}

export default ViewIconButton;
