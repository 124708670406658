import React, { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { BaseSchema } from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Student } from "~/backendApi/model";
import useApiCall from "~/hooks/useApiCall";
import { useApiClient } from "~/hooks/apiContext";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import {
  FormStatusError,
  Month,
  monthNames,
  MonthSelectFormItem,
  SelectFormItem,
  SubmitButton,
  TextFormItem,
} from "~/components/forms/index";
import ErrorMessages from "~/components/ErrorMessages";
import Row from "~/components/Row";
import Col from "~/components/Col";
import Button from "~/components/Button";
import ViewIconButton from "~/components/ViewIconButton";
import "./StudentForm.css";

type StudentFormValues = {
  readonly nickname: string;
  readonly userNameName: string;
  readonly userNameMathematician: string;
  readonly password: string;
  readonly confirmPassword: string;
  readonly birthdayMonth: Month;
};

type StudentFormProps = {
  readonly student?: Pick<
    Student,
    "username" | "firstName" | "monthOfBirth" | "yearOfBirth"
  >;
  readonly onSubmit: (values: StudentFormValues) => Promise<void>;
};

function StudentForm({
  student,
  onSubmit: providedOnSubmit,
}: StudentFormProps) {
  const {
    data: mathData,
    loading: mathLoading,
    error: mathError,
  } = useApiCall(useCallback((a) => a.mathematicians(), []));
  const mathematicians = mathData ?? [];

  const onSubmit = useFormikStatusSubmit(providedOnSubmit);
  const hasInitialValues = !!student;
  const validationSchema = useMemo(() => {
    const baseShape = {
      nickname: yup.string().required(),
      userNameName: yup.string().required(),
      userNameMathematician: yup.string().required(),
      birthdayMonth: yup
        .object({
          monthName: yup.string().oneOf(monthNames).required(),
          year: yup.number().required(),
        })
        .default(undefined)
        .required() as BaseSchema<Month>,
    };
    if (hasInitialValues) {
      return yup.object(baseShape).required();
    }

    return yup
      .object({
        ...baseShape,
        password: yup.string().required(),
        confirmPassword: yup.string().when("password", {
          is: (val: string) => !!(val && val.length > 0),
          then: yup
            .string()
            .required()
            .oneOf([yup.ref("password")], "Password must match"),
        }),
      })
      .required();
  }, [hasInitialValues]);
  const initialValues = useMemo(() => {
    if (!student) {
      return {
        nickname: "",
        userNameName: "",
        userNameMathematician: "",
        password: "",
        confirmPassword: "",
        birthdayMonth: undefined,
      };
    }
    const [userNameName, userNameMathematician] = (
      student.username ?? ""
    ).split("@");
    return {
      nickname: student.firstName,
      userNameName,
      userNameMathematician,
      birthdayMonth:
        student.monthOfBirth && student.yearOfBirth
          ? {
              monthName: student.monthOfBirth,
              year: student.yearOfBirth,
            }
          : undefined,
    };
  }, [student]);
  const formikBag = useFormik({
    onSubmit,
    initialValues: initialValues as any,
    validationSchema,
  });
  const { values } = formikBag;

  // Validate username
  const formUsername = `${values.userNameName}@${values.userNameMathematician}`;
  const [verifiedUsername, setVerifiedUsername] = useState<string | undefined>(
    student?.username,
  );
  const isUsernameVerified = formUsername === verifiedUsername;
  const [isVerifying, setVerifying] = useState(false);
  const [verifyError, setVerifyError] = useState<Error | undefined>(undefined);
  const apiClient = useApiClient();
  const onVerifyUserNameClick = async () => {
    setVerifyError(undefined);
    setVerifying(true);
    try {
      const response = await apiClient.checkUserName(formUsername);
      if (response === true) {
        setVerifiedUsername(formUsername);
        return;
      }
      throw new Error(response);
    } catch (e) {
      setVerifyError(e as Error);
    } finally {
      setVerifying(false);
    }
  };
  const now = new Date();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <FormikProvider value={formikBag}>
      <Form>
        <ErrorMessages errors={[mathError]} />
        <div className="mb-2 text-center">
          We take privacy seriously and only collect information needed for
          keeping track of progress and to improve the game.
        </div>
        <div className="mb-2">
          <Row>
            <Col>
              <TextFormItem
                label="First name"
                name="nickname"
                placeholder="nickname"
                autoFocus
                disabled={isVerifying}
              />
            </Col>
          </Row>
          <Row className="username-row">
            <Col>
              <TextFormItem
                label="Username"
                name="userNameName"
                placeholder="nickname"
                style={{ flex: 1 }}
                disabled={isVerifying || hasInitialValues}
              />
              {isUsernameVerified && (
                <em style={{ color: "green", fontSize: "80%" }}>
                  Username available
                </em>
              )}
            </Col>
            <Col style={{ fontWeight: "bold", marginTop: 15, flex: 0 }}>@</Col>
            <Col>
              <SelectFormItem
                label="Mathemetician"
                name="userNameMathematician"
                options={mathematicians.map((m) => ({
                  value: m.name.toLowerCase(),
                  label: m.name,
                }))}
                isSearchable={false}
                isLoading={mathLoading}
                style={{ flex: 1 }}
                disabled={isVerifying || hasInitialValues}
              />
              {isUsernameVerified && <em>&nbsp;</em>}
            </Col>
          </Row>
          <ErrorMessages errors={[verifyError]} />
          {!hasInitialValues && (
            <Row>
              <Col className="password-col">
                <TextFormItem
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="password"
                  autoComplete="off"
                  disabled={isVerifying}
                />
                <ViewIconButton
                  visible={showPassword}
                  onToggle={setShowPassword}
                />
              </Col>
              <Col className="password-col">
                <TextFormItem
                  label="Confirm password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="re-enter password"
                  autoComplete="off"
                  disabled={isVerifying}
                />
                <ViewIconButton
                  visible={showConfirmPassword}
                  onToggle={setShowConfirmPassword}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <MonthSelectFormItem
                label="Birthday month"
                name="birthdayMonth"
                minDate={new Date(now.getFullYear() - 100, now.getMonth())}
                maxDate={new Date(now.getFullYear() - 5, now.getMonth())}
                disabled={isVerifying}
              />
            </Col>
          </Row>
        </div>
        <FormStatusError />
        {isUsernameVerified ? (
          <SubmitButton label="Submit" />
        ) : (
          <div className="submit-button-row">
            <Button
              type="button"
              colour="blue"
              variant="solid"
              size="large"
              onClick={onVerifyUserNameClick}
              disabled={!values.userNameName || !values.userNameMathematician}
            >
              {isVerifying ? "Checking..." : "Check if username is available"}
            </Button>
          </div>
        )}
      </Form>
    </FormikProvider>
  );
}

export type { StudentFormValues };
export default StudentForm;
