import React from "react";
import CardPage from "~/components/CardPage";
import { Link, navigate } from "gatsby";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
  FormStatusError,
  SubmitButton,
  TextFormItem,
} from "~/components/forms/index";
import CleverButton from "~/components/CleverButton";
import GoogleSsoButton from "~/components/GoogleSsoButton";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import { useApiClient } from "~/hooks/apiContext";
import { useAuthState } from "~/hooks/authContext";
import { SchemaFieldValues } from "~/utils/SchemaFieldValues";
import Seo from "~/components/Seo";
import authBackgroundImage from "~/images/auth-background.jpg";
import "./Login.css";

const validationSchema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

type FormValues = SchemaFieldValues<typeof validationSchema>;

const initialValues: FormValues = {
  username: "",
  password: "",
};

function Login() {
  const apiClient = useApiClient();
  const authState = useAuthState();

  const onSubmit = useFormikStatusSubmit(
    async ({ username, password }: FormValues) => {
      const response = await apiClient.login(username, password);
      if (authState.type !== "signedOut") {
        throw new Error("Invalid state - should be signed out");
      }
      authState.signIn({
        username: response.username,
        emailAddress: response.emailAddress,
        loginType: response.loginType,
        type: response.userType,
        authToken: response.authToken,
      });
      await navigate("/dashboard/");
    },
  );

  return (
    <CardPage backgroundImage={authBackgroundImage}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Form className="login-form">
          <h2 className="text-center mb-2">
            Sign In to
            <br />
            ProblemScape!
          </h2>
          <div className="mb-2">
            <TextFormItem name="username" label="Username" autoFocus />
            <TextFormItem type="password" name="password" label="Password" />
          </div>
          <FormStatusError />
          <SubmitButton label="Sign In" />
          <div style={{ textAlign: "center", marginTop: 15 }}>
            Please use the buttons below to login if an account has been created
            at school with Clever or Google.
          </div>
          <div className="sso-button-container">
            <CleverButton variant="signin" />
            <GoogleSsoButton />
          </div>
          <div className="below-form">
            <div className="line-item">
              Forgot Password? <Link to="/forgot-password/">Reset</Link>
            </div>
            <div className="line-item">
              Don&apos;t have an account? <Link to="/signup/">Signup</Link>
            </div>
          </div>
        </Form>
      </Formik>
    </CardPage>
  );
}

export function Head() {
  return <Seo title="Login" />;
}

export default Login;
