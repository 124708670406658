import pluralize from "pluralize";

function formatMonths(numMonths: number) {
  if (numMonths % 12 === 0) {
    return pluralize("year", numMonths / 12, true);
  }
  return pluralize("month", numMonths, true);
}

export default formatMonths;
