import React from "react";
import { RouteComponentProps } from "@reach/router";
import LegacySeo from "~/components/LegacySeo";
import useApiQuery from "~/hooks/useApiQuery";
import ErrorMessages from "~/components/ErrorMessages";
import Breadcrumbs from "~/components/Breadcrumbs";
import StudentsTable from "../StudentsTable";
import DashboardPage from "../DashboardPage";

type CleverStudentsProps = RouteComponentProps<{ readonly classId: string }>;

function CleverStudents({ classId }: CleverStudentsProps) {
  const {
    isLoading: sLoading,
    error: sError,
    data: sData,
  } = useApiQuery(["cleverSections", { classId }, "sections"], (apiClient) =>
    apiClient.cleverSectionStudents(classId!),
  );
  const { error: cError, data: cData } = useApiQuery(
    ["cleverSections", { classId }],
    (apiClient) => apiClient.getCleverSection(classId!),
    {
      staleTime: Infinity,
    },
  );
  const heading = cData?.name ?? "Class";

  return (
    <>
      <LegacySeo title="Students" />
      <ErrorMessages errors={[sError, cError]} />
      <DashboardPage heading={heading} subHeading="Students">
        <Breadcrumbs
          items={[{ to: "/dashboard/", label: "Classes" }]}
          currentLabel={heading}
        />
        <StudentsTable
          showDownloadInstructions={false}
          viewStudentToPrefix={`/dashboard/classes/${classId}/students`}
          error={sError as any}
          isLoading={sLoading}
          data={sData}
        />
      </DashboardPage>
    </>
  );
}

export default CleverStudents;
