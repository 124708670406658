function formatSecondsToTimeMinutes(secs: number) {
  if (secs === 0) {
    return undefined;
  }

  const minutes = Math.ceil(secs / 60);
  const hours = Math.floor(minutes / 60);
  const remMins = minutes % 60;
  const minsOutput = `${remMins}m`;
  if (hours === 0) {
    return minsOutput;
  }
  return `${hours}h ${minsOutput}`;
}

function formatSecondsToTimeSeconds(secs: number) {
  if (secs === 0) {
    return "0s";
  }

  const secsRemaining = Math.floor(secs % 60);
  const secsOutput = `${secsRemaining}s`;
  const minutes = Math.floor(secs / 60);
  if (minutes === 0) {
    return secsOutput;
  }

  const hours = Math.floor(minutes / 60);
  const remMins = minutes % 60;
  const minsOutput = `${remMins}m ${secsOutput}`;
  if (hours === 0) {
    return minsOutput;
  }
  return `${hours}h ${minsOutput}`;
}

export { formatSecondsToTimeSeconds, formatSecondsToTimeMinutes };
