import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import useCookie from "react-use/lib/useCookie";
import { useLocation } from "@gatsbyjs/reach-router";

function usePromotionsCookie() {
  const { protocol } = useLocation();
  const [rawValue, setValue, removeValue] = useCookie("viewedPromotions");
  const viewedPromotions = (() => {
    if (!rawValue) {
      return [];
    }

    let parsed: unknown;
    try {
      parsed = JSON.parse(rawValue);
    } catch {
      return [];
    }

    if (!Array.isArray(parsed)) {
      return [];
    }

    return parsed.filter((p) => typeof p === "string");
  })();

  return {
    addPromotionView(promo: string) {
      const newViewed = uniq([...viewedPromotions, promo]);
      if (isEqual(newViewed, viewedPromotions)) {
        return;
      }

      setValue(JSON.stringify(newViewed), {
        expires: 365,
        secure: protocol === "https:",
        sameSite: "strict",
      });
    },
    removePromotionViews(promos: readonly string[]) {
      const newViewed = viewedPromotions.filter((p) => !promos.includes(p));
      if (newViewed.length === 0) {
        removeValue();
        return;
      }

      setValue(JSON.stringify(newViewed), {
        expires: 365,
        secure: protocol === "https:",
        sameSite: "strict",
      });
    },
    viewedPromotions,
  };
}

export default usePromotionsCookie;
