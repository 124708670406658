import React from "react";
import { Link } from "gatsby";
import { buttonClassName, ButtonProps } from "./Button";
import "./Button.css";

type UniversalLinkButtonProps = Omit<ButtonProps, "type"> & {
  readonly to: string;
  readonly forceExternal?: boolean;
};

function UniversalLinkButton({
  children,
  className,
  disabled,
  colour,
  variant,
  forceExternal,
  size,
  to,
}: UniversalLinkButtonProps) {
  const finalClassName = buttonClassName({ colour, size, variant, className });
  if (forceExternal || to.startsWith("http://") || to.startsWith("https://")) {
    return (
      <a
        href={to}
        className={finalClassName}
        aria-disabled={disabled}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={finalClassName} aria-disabled={disabled}>
      {children}
    </Link>
  );
}

export type { UniversalLinkButtonProps };
export default UniversalLinkButton;
