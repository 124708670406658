import React from "react";
import { Router } from "@reach/router";
import { useAuthState } from "~/hooks/authContext";
import Seo from "~/components/Seo";
import GoogleCourses from "~/features/dashboard/google/GoogleCourses";
import GoogleStudents from "~/features/dashboard/google/GoogleStudents";
import ViewGoogleStudent from "~/features/dashboard/google/ViewGoogleStudent";
import Login from "~/features/dashboard/Login";
import PublicOnlyRoute from "~/features/dashboard/PublicOnlyRoute";
import PrivateRoute from "~/features/dashboard/PrivateRoute";
import EmailGuardianStudents from "~/features/dashboard/EmailGuardianStudents";
import ViewStudent from "~/features/dashboard/ViewStudent";
import CleverClasses from "~/features/dashboard/clever/CleverClasses";
import RouteGroup from "~/features/dashboard/RouteGroup";
import CleverStudents from "~/features/dashboard/clever/CleverStudents";
import ViewCleverStudent from "~/features/dashboard/clever/ViewCleverStudent";
import StudentDashboard from "~/features/dashboard/StudentDashboard";
import PaymentComplete from "~/features/dashboard/payment/PaymentComplete";

function DashboardSection() {
  const authState = useAuthState();
  return (
    <Router basepath="/dashboard">
      <PublicOnlyRoute path="/login/" component={Login} />
      <PrivateRoute default component={Login} />
      <PrivateRoute path="/payment-complete/" component={PaymentComplete} />
      {authState.type === "signedIn" &&
        (authState.user.type === "teacher" ||
          authState.user.type === "parent") &&
        authState.user.loginType.type === "email" && (
          <PrivateRoute path="/">
            <EmailGuardianStudents path="/" />
            <ViewStudent path="/students/:id/" />
          </PrivateRoute>
        )}
      {authState.type === "signedIn" &&
        authState.user.type === "teacher" &&
        authState.user.loginType.type === "clever" && (
          <>
            <PrivateRoute path="/">
              <CleverClasses path="/" />
              <RouteGroup path={`/classes/:classId/students/`}>
                <CleverStudents path="/" />
                <ViewCleverStudent path="/:id/" />
              </RouteGroup>
            </PrivateRoute>
          </>
        )}
      {authState.type === "signedIn" &&
        authState.user.type === "teacher" &&
        authState.user.loginType.type === "google" && (
          <>
            <PrivateRoute path="/">
              <GoogleCourses path="/" />
              <RouteGroup path={`/classes/:classId/students/`}>
                <GoogleStudents path="/" />
                <ViewGoogleStudent path="/:id/" />
              </RouteGroup>
            </PrivateRoute>
          </>
        )}
      {authState.type === "signedIn" && authState.user.type === "student" && (
        <PrivateRoute path="/" component={StudentDashboard} />
      )}
    </Router>
  );
}

export function Head() {
  return <Seo title="Dashboard" />;
}

/*
Wasn't working. Verified on minimal reproduction on gatsby simple-auth example.
<Router basepath="/dashboard">
  <PublicOnlyRoute path="/login" component={Login} />
  <Layout default>
    <PrivateRoute path="/" component={DashboardHome} />
    <PrivateRoute path="/profile" component={Profile} />
    <PrivateRoute path="/details" component={Details} />
  </Layout>
</Router>

Does work. Seems to be rendering client route still
<Router basepath="/dashboard">
  <PublicOnlyRoute path="/login" component={Login} />
  <Layout default>
    <DashboardHome path="/" />
    <PrivateRoute path="/profile" component={Profile} />
    <PrivateRoute path="/details" component={Details} />
  </Layout>
</Router>
 */

export default DashboardSection;
