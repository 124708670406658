import React, { useState } from "react";
import * as yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import Modal, { ModalProps } from "~/components/Modal";
import {
  FormStatusSuccess,
  FormStatusError,
  SubmitButton,
  TextFormItem,
} from "~/components/forms/index";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import { useApiClient } from "~/hooks/apiContext";
import { SchemaFieldValues } from "~/utils/SchemaFieldValues";
import { Student } from "~/backendApi/model";
import Row from "~/components/Row";
import Col from "~/components/Col";
import ViewIconButton from "~/components/ViewIconButton";

const validationSchema = yup
  .object({
    newPassword: yup.string().required(),
    confirmPassword: yup.string().when("newPassword", {
      is: (val: string) => !!(val && val.length > 0),
      then: yup
        .string()
        .required()
        .oneOf([yup.ref("newPassword")], "Password must match"),
    }),
  })
  .required();

type FormValues = SchemaFieldValues<typeof validationSchema>;

const initialValues: FormValues = {
  newPassword: "",
  confirmPassword: "",
};

type ChangeStudentPasswordModalProps = Pick<ModalProps, "onRequestClose"> & {
  readonly student?: Pick<Student, "id" | "firstName" | "username">;
};

function ChangeStudentPasswordModal({
  student,
  ...props
}: ChangeStudentPasswordModalProps) {
  const apiClient = useApiClient();
  const onSubmit = useFormikStatusSubmit(
    async ({ newPassword }: FormValues, helpers: FormikHelpers<FormValues>) => {
      if (!student) {
        return;
      }

      await apiClient.changeStudentPassword(student.id, newPassword);
      helpers.resetForm();
    },
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Modal
      title={`Change Password for ${student?.firstName} - ${student?.username}`}
      isOpen={!!student}
      {...props}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Form>
          <Row>
            <Col className="password-col">
              <TextFormItem
                type={showPassword ? "text" : "password"}
                name="newPassword"
                label="New password"
                autoComplete="off"
              />
              <ViewIconButton
                visible={showPassword}
                onToggle={setShowPassword}
              />
            </Col>
            <Col className="password-col">
              <TextFormItem
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                label="Confirm password"
                autoComplete="off"
              />
              <ViewIconButton
                visible={showConfirmPassword}
                onToggle={setShowConfirmPassword}
              />
            </Col>
          </Row>
          <FormStatusError />
          <FormStatusSuccess>Student Password updated.</FormStatusSuccess>
          <SubmitButton label="Change" />
        </Form>
      </Formik>
    </Modal>
  );
}

export default ChangeStudentPasswordModal;
