import React, { ChangeEvent, ComponentType } from "react";
import { Link } from "gatsby";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { RouteComponentProps } from "@reach/router";
import ErrorMessages from "~/components/ErrorMessages";
import LoadingSpinner from "~/components/LoadingSpinner";
import { GoogleCourse } from "~/backendApi/model";
import { useApiMutation } from "~/hooks/apiContext";
import useApiQuery from "~/hooks/useApiQuery";
import DashboardPage from "../DashboardPage";
import DashboardTable from "../DashboardTable";

const listQueryKey = ["googleCourses"];

function GoogleCourses() {
  const { error, isLoading, data } = useApiQuery(listQueryKey, (api) =>
    api.googleCourses(),
  );
  const queryClient = useQueryClient();
  const {
    isLoading: changeLoading,
    error: changeError,
    mutate,
  } = useApiMutation<
    { readonly id: string; readonly enabled: boolean },
    unknown,
    { previousCourses?: ReadonlyArray<GoogleCourse> }
  >((api, { id, enabled }) => api.setGoogleCourseEnabled(id, enabled), {
    onMutate: async ({ id, enabled }) => {
      await queryClient.cancelQueries(listQueryKey);
      const previousCourses =
        queryClient.getQueryData<ReadonlyArray<GoogleCourse>>(listQueryKey);
      queryClient.setQueryData<ReadonlyArray<GoogleCourse>>(
        listQueryKey,
        (p) => {
          if (!p) {
            return p;
          }
          return p.map((s) => {
            if (s.id === id) {
              return { ...s, enabled };
            }
            return s;
          });
        },
      );
      return { previousCourses };
    },
    onSuccess() {
      toast.success("Class updated");
    },
    onError: (_, _2, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData<ReadonlyArray<GoogleCourse>>(
        listQueryKey,
        context?.previousCourses,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(listQueryKey);
    },
  });
  const onEnabledChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
    mutate({ id, enabled: e.target.checked });
  };

  return (
    <DashboardPage heading="Teacher Dashboard" subHeading="Classes">
      <ErrorMessages errors={[error, changeError]} />
      <DashboardTable>
        <thead>
          <DashboardTable.Tr index={0}>
            <th>Name</th>
            <th>Enabled</th>
          </DashboardTable.Tr>
        </thead>
        <tbody>
          {data?.map((d, i) => (
            <DashboardTable.Tr key={d.id} index={i + 1}>
              <td>
                <Link to={`/dashboard/classes/${d.id}/students/`}>
                  {d.name}
                </Link>
              </td>
              <td>
                <input
                  type="checkbox"
                  value="1"
                  checked={d.enabled}
                  disabled={isLoading || changeLoading}
                  onChange={(e) => onEnabledChange(d.id, e)}
                />
              </td>
            </DashboardTable.Tr>
          ))}
          {data && data.length === 0 && (
            <DashboardTable.Tr index={1}>
              <td colSpan={4}>
                <em>
                  RoundEd Learning doesn't have access to any of your Google
                  classes.
                </em>
              </td>
            </DashboardTable.Tr>
          )}
          {isLoading && (
            <DashboardTable.Tr index={1}>
              <td colSpan={4}>
                <LoadingSpinner />
              </td>
            </DashboardTable.Tr>
          )}
        </tbody>
      </DashboardTable>
    </DashboardPage>
  );
}

export default GoogleCourses as ComponentType<RouteComponentProps>;
