import React from "react";
import { Link } from "gatsby";
import "./Breadcrumbs.css";

type BreadcrumbItem = {
  readonly to: string;
  readonly label: string;
};

type BreadcrumbsProps = {
  readonly items?: ReadonlyArray<BreadcrumbItem>;
  readonly currentLabel: string;
};

function Breadcrumbs({ items, currentLabel }: BreadcrumbsProps) {
  return (
    <ol className="breadcrumbs">
      {items?.map((item) => (
        <li key={item.to}>
          <Link to={item.to}>{item.label}</Link>
        </li>
      ))}
      <li>
        <span>{currentLabel}</span>
      </li>
    </ol>
  );
}

export type { BreadcrumbItem };
export default Breadcrumbs;
