import React, { ComponentType } from "react";
import { useAuthState } from "~/hooks/authContext";

type PublicOnlyRouteProps = {
  readonly path: string;
  readonly component: ComponentType<any>;
};

function PublicOnlyRoute({
  component: Component,
  ...rest
}: PublicOnlyRouteProps) {
  const authState = useAuthState();
  if (authState.type === "signedIn") {
    // Was using reach router navigate(). Didn't work though with:
    // 1. signed out
    // 2. navigate to /dashboard/profile
    // 3. It redirects to /dashboard/login but only shows an empty page
    window.location.href = "/dashboard/";
    return null;
  }
  return <Component {...rest} />;
}

export default PublicOnlyRoute;
