function commaAndList(items: readonly string[]) {
  if (items.length === 0) {
    return "";
  }
  if (items.length === 1) {
    return items[0];
  }
  const initialItems = items.slice(0, items.length - 1);
  const lastItem = items[items.length - 1];
  return initialItems.join(", ") + " and " + lastItem;
}

export { commaAndList };
