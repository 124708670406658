import React, { useState } from "react";
import Button from "~/components/Button";
import { useSignedInAuthState } from "~/hooks/authContext";
import ConfirmDeleteAccountModal from "./ConfirmDeleteAccountModal";

type EmailDangerZoneProps = {
  readonly hasStudent: boolean;
};

function EmailDangerZone({ hasStudent }: EmailDangerZoneProps) {
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { user, signOut } = useSignedInAuthState();
  const onDeleteComplete = async () => {
    signOut();
    window.location.assign("/");
  };

  if (hasStudent) {
    return null;
  }

  return (
    <>
      <ConfirmDeleteAccountModal
        profile={isConfirmOpen ? user : undefined}
        onComplete={onDeleteComplete}
        onRequestClose={() => setConfirmOpen(false)}
      />
      <div style={{ marginTop: 40 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            type="button"
            onClick={() => setConfirmOpen(true)}
            colour="red"
            size="small"
            variant="solid"
          >
            Delete my Account
          </Button>
        </div>
      </div>
    </>
  );
}

export default EmailDangerZone;
