import pluralize from "pluralize";

function formatMoney(amount: number) {
  return `$${Math.round(amount / 100)}`;
}

type Price = {
  readonly price: number;
  readonly interval: string;
  readonly intervalCount: number;
};

/** @deprecated Not using intervals any more */
function formatIntervalPrice(price: Price) {
  return `${formatMoney(price.price)} / ${
    price.intervalCount > 1 ? price.intervalCount : ""
  } ${pluralize(price.interval, price.intervalCount)}`;
}

export { formatMoney, formatIntervalPrice };
