import React, { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Modal, { ModalProps } from "~/components/Modal";
import Button from "~/components/Button";
import ErrorMessages from "~/components/ErrorMessages";
import { useApiMutation } from "~/hooks/apiContext";
import { Student } from "~/backendApi/model";

type ConfirmRemoveFromSubscriptionModalProps = Pick<
  ModalProps,
  "onRequestClose"
> & {
  readonly student?: Pick<Student, "id" | "username">;
  readonly onComplete: () => void;
};

function ConfirmRemoveFromSubscriptionModal({
  student,
  onRequestClose,
  onComplete,
}: ConfirmRemoveFromSubscriptionModalProps) {
  const queryClient = useQueryClient();
  const { reset, mutate, error, isLoading } = useApiMutation(
    async (c) => {
      if (!student) {
        throw new Error("Invalid state no student");
      }
      return c.removeStudentFromSubscription(student.id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(["students"]);
        queryClient.invalidateQueries(["subscription"]);
        onComplete();
      },
    },
  );

  const isOpen = !!student;
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal
      title="Are you sure?"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      disabled={isLoading}
    >
      <ErrorMessages errors={[error]} />
      <p className="text-center">
        Are you sure you want to remove {student?.username} from the
        subscription?
      </p>
      <div className="modal-footer-buttons">
        <Button
          type="button"
          colour="red"
          variant="outline"
          size="small"
          onClick={onRequestClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        {student && (
          <Button
            type="button"
            colour="red"
            variant="solid"
            size="small"
            onClick={() => mutate({ studentId: student.id })}
            disabled={isLoading || !student}
          >
            Yes
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default ConfirmRemoveFromSubscriptionModal;
