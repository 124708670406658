import React, { useEffect, useState } from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import LoadingSpinner from "~/components/LoadingSpinner";
import UniversalLinkButton from "~/components/UniversalLinkButton";
import { useApiClient } from "~/hooks/apiContext";
import { commaAndList } from "~/utils/string";
import DashboardPage from "../DashboardPage";

function PaymentComplete() {
  const [result, setResult] = useState<false | readonly string[] | undefined>();
  const apiClient = useApiClient();

  const { search } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(search);
    const studentIds = params.getAll("studentIds[]");
    if (studentIds.length === 0) {
      setResult(false);
      return;
    }

    (async () => {
      // TODO: Smaller api call of just ids
      const allStudents = await apiClient.students();
      const matchingStudentNames = allStudents
        .filter((s) => studentIds.includes(s.id))
        .map((s, i) => s.firstName || s.username || `Student ${i}`);
      setResult(matchingStudentNames);
    })();
  }, [apiClient, search]);

  return (
    <DashboardPage heading="Payment">
      {result === undefined ? (
        <LoadingSpinner />
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {result ? (
              <>
                <p>
                  Thank you for signing up {commaAndList(result)} to go on this
                  adventure to master foundational algebra concepts!
                </p>
              </>
            ) : (
              <p>There was a problem with your payment</p>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 20,
              }}
            >
              <UniversalLinkButton
                to="/dashboard/"
                colour="red"
                size="small"
                variant="solid"
              >
                Go to dashboard
              </UniversalLinkButton>
            </div>
          </div>
        </>
      )}
    </DashboardPage>
  );
}

export default PaymentComplete;
