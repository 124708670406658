import { useCallback, useEffect, useState } from "react";
import noop from "lodash/noop";
import { ApiClient } from "~/backendApi/index";
import { useApiClient } from "./apiContext";

type LoadingApiResult<T> = {
  readonly loading: true;
  readonly error: undefined;
  readonly data?: T;
  readonly refetch: () => void;
  readonly clearData: () => void;
};

type ErrorApiResult = {
  readonly loading: false;
  readonly error: Error;
  readonly data: undefined;
  readonly refetch: () => void;
  readonly clearData: () => void;
};

type SuccessApiResult<T> = {
  readonly loading: false;
  readonly data: T;
  readonly error: undefined;
  readonly refetch: () => void;
  readonly clearData: () => void;
};

type ApiCallResult<T> =
  | LoadingApiResult<T>
  | SuccessApiResult<T>
  | ErrorApiResult;

function createLoadingResult<T>(): LoadingApiResult<T> {
  return {
    loading: true,
    error: undefined,
    data: undefined,
    clearData: noop,
    refetch: noop,
  };
}

/* @deprecated */
function useApiCall<T>(
  perform: (api: ApiClient) => Promise<T>,
): ApiCallResult<T> {
  const api = useApiClient();
  const [result, setResult] = useState<ApiCallResult<T>>(
    createLoadingResult<T>(),
  );
  const clearData = useCallback(() => {
    setResult((p) => {
      if (!p.loading && p.data) {
        return createLoadingResult<T>();
      }
      if (p.data) {
        return { ...p, data: undefined } as ApiCallResult<T>;
      }
      return p;
    });
  }, []);
  const runCall = useCallback(() => {
    setResult((p) => ({
      loading: true,
      error: undefined,
      data: p.data,
      clearData,
      refetch: noop,
    }));
    (async () => {
      try {
        const data = await perform(api);
        setResult({
          loading: false,
          data,
          error: undefined,
          clearData,
          refetch: runCall,
        });
      } catch (error) {
        setResult({
          loading: false,
          data: undefined,
          error: error as Error,
          clearData,
          refetch: runCall,
        });
      }
    })();
  }, [api, perform, clearData]);

  // Perform call every time callback changes
  useEffect(() => {
    runCall();
  }, [runCall]);

  return result;
}

export default useApiCall;
