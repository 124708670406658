import React from "react";
import DashboardDownloadLinks from "~/components/DashboardDownloadLinks";
import BaseViewStudent from "./BaseViewStudent";

function StudentDashboard() {
  // TODO: Proper typing for profile
  return (
    <div className="dashboard-home">
      <section>
        <BaseViewStudent
          gameTimesQueryKey={["student", "times"]}
          gameTimesApiCall={(apiClient) => apiClient.getMyGameTimes()}
          queryKey={["profile"]}
          apiCall={(a) => a.getProfile() as any}
        >
          <p>Are you ready to start playing ProblemScape?</p>
          <ol
            style={{
              // maxWidth: 600,
              textAlign: "left",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 20,
            }}
          >
            <li>
              Download the game on your device using the links below, or by
              searching for ProblemScape in the app stores
            </li>
            <li>
              Open the app, login with this id, and start playing and learning!
            </li>
          </ol>
          <p>
            ProblemScape is currently available for download on iPads, Android
            tablets, Chromebooks, and PCs (both macOS and Windows). Please note
            that ProblemScape is not compatible with phones.
          </p>
          <DashboardDownloadLinks />
        </BaseViewStudent>
      </section>
    </div>
  );
}

export default StudentDashboard;
