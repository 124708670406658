import React from "react";
import { RouteComponentProps } from "@reach/router";
import BaseViewStudent from "./BaseViewStudent";

type ViewStudentProps = RouteComponentProps<{ readonly id: string }>;

function ViewStudent({ id }: ViewStudentProps) {
  return (
    <BaseViewStudent
      gameTimesQueryKey={["student", "times", { id }]}
      gameTimesApiCall={(apiClient) => apiClient.getStudentGameTimes(id!)}
      breadcrumbItems={[{ to: "/dashboard/", label: "Dashboard" }]}
      queryKey={["student", { id: id! }]}
      apiCall={(a) => a.getStudent(id!)}
    />
  );
}

export default ViewStudent;
