import React, { ReactNode } from "react";
import "./DashboardPage.css";

type DashboardPageProps = {
  readonly heading: string;
  readonly subHeading?: string;
  readonly children?: ReactNode | ReadonlyArray<ReactNode>;
};

function DashboardPage({ heading, subHeading, children }: DashboardPageProps) {
  return (
    <div className="dashboard-page">
      <h2>{heading}</h2>
      {subHeading && <h3>{subHeading}</h3>}
      {children}
    </div>
  );
}

export default DashboardPage;
