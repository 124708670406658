import React from "react";
import Modal, { ModalProps } from "~/components/Modal";
import { useApiClient } from "~/hooks/apiContext";
import StudentForm, { StudentFormValues } from "./StudentForm";

type AddStudentModalProps = Pick<ModalProps, "isOpen" | "onRequestClose"> & {
  readonly onComplete: (studentId: string) => void;
};

function AddStudentModal({
  onComplete,
  isOpen,
  onRequestClose,
}: AddStudentModalProps) {
  const apiClient = useApiClient();

  const onSubmit = async ({
    userNameName,
    userNameMathematician,
    birthdayMonth,
    confirmPassword,
    nickname,
    ...values
  }: StudentFormValues) => {
    const studentId = await apiClient.createStudent({
      firstName: nickname,
      username: `${userNameName}@${userNameMathematician}`,
      monthOfBirth: birthdayMonth.monthName,
      yearOfBirth: birthdayMonth.year,
      ...values,
    });
    onComplete(studentId);
  };

  return (
    <Modal
      title="Create Student Account"
      size="lg"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {/* this forces form to reset when close */}
      {isOpen && <StudentForm onSubmit={onSubmit} />}
    </Modal>
  );
}

export default AddStudentModal;
