import clsx from "clsx";
import React, { ReactNode } from "react";
import BaseModal from "react-modal";
import Button from "./Button";
import "./Modal.css";

BaseModal.setAppElement(`#___gatsby`);

type ModalProps = {
  readonly title?: string;
  readonly className?: string;
  readonly isOpen: boolean;
  readonly onRequestClose: () => void;
  readonly size?: "lg" | "sm";
  readonly children: ReactNode;
  readonly disabled?: boolean;
};

function Modal({
  size,
  disabled,
  title,
  isOpen,
  onRequestClose,
  children,
  className,
}: ModalProps) {
  return (
    <BaseModal
      className={clsx(className, {
        "modal-size-sm": size !== "lg",
        "modal-size-lg": size === "lg",
      })}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="modal-top-buttons">
        <div className="modal-top-button-cell" />
        {title ? <h2>{title}</h2> : <div />}
        <div className="modal-top-button-cell">
          <Button
            colour="blue"
            variant="solid"
            size="large"
            type="button"
            onClick={onRequestClose}
            disabled={disabled}
          >
            X
          </Button>
        </div>
      </div>
      {children}
    </BaseModal>
  );
}

export type { ModalProps };
export default Modal;
