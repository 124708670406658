import React, { ReactElement } from "react";

type RouteGroupProps = {
  readonly path: string;
  readonly children: ReactElement | ReadonlyArray<ReactElement>;
};

function RouteGroup({ children }: RouteGroupProps) {
  return <>{children}</>;
}

export default RouteGroup;
