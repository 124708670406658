import React, { ReactNode } from "react";
import clsx from "clsx";
import "./DashboardTable.css";

type DashboardTableProps = {
  readonly children: ReactNode | ReadonlyArray<ReactNode>;
};

function DashboardTable({ children }: DashboardTableProps) {
  return <table className="dashboard-table">{children}</table>;
}

type DashboardTableTrProps = {
  readonly index: number;
  readonly children: ReactNode;
};

function DashboardTableTr({ index, children }: DashboardTableTrProps) {
  return (
    <tr
      className={clsx({
        "row-odd": index % 2 === 0,
        "row-even": index % 2 !== 0,
      })}
    >
      {children}
    </tr>
  );
}

DashboardTable.Tr = DashboardTableTr;

export default DashboardTable;
