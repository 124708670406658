import React, { ReactNode } from "react";
import Card from "./Card";
import "./CardPage.css";

type CardPageProps = {
  readonly backgroundImage?: string;
  readonly children: ReactNode;
};

function CardPage({ backgroundImage, children }: CardPageProps) {
  return (
    <div
      className="card-page"
      style={
        backgroundImage
          ? {
              background: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }
          : {}
      }
    >
      <Card>{children}</Card>
    </div>
  );
}

export default CardPage;
