import React, { ElementType, ReactNode } from "react";
import { RouteComponentProps } from "@reach/router";
import { useAuthState } from "~/hooks/authContext";

type PrivateRouteProps = RouteComponentProps & {
  readonly component?: ElementType<any>;
  readonly children?: ReactNode | ReadonlyArray<ReactNode>;
};

function PrivateRoute({
  component: Component = "div",
  ...rest
}: PrivateRouteProps) {
  const authState = useAuthState();
  if (authState.type === "signedOut") {
    // Was using reach router navigate(). Didn't work though with:
    // 1. signed out
    // 2. navigate to /dashboard/profile
    // 3. It redirects to /dashboard/login but only shows an empty page
    window.location.href = "/dashboard/login/";
    return null;
  }
  return <Component {...rest} />;
}

export default PrivateRoute;
