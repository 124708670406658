import React from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import { useCleverClientId } from "~/hooks/authContext";
import cleverSignUpImage from "~/images/signup-clever.png";
import cleverSignInImage from "~/images/signin-clever.png";

type CleverButtonProps = {
  readonly variant: "signup" | "signin";
};

const images: { [key in CleverButtonProps["variant"]]: string } = {
  signup: cleverSignUpImage,
  signin: cleverSignInImage,
};

function CleverButton({ variant }: CleverButtonProps) {
  const { origin } = useLocation();

  const redirectUri = `${origin}/oauth/clever/`;
  const cleverClientId = useCleverClientId();
  const params = new URLSearchParams();
  params.set("response_type", "code");
  params.set("redirect_uri", redirectUri);
  params.set("client_id", cleverClientId);
  const cleverUrl = `https://clever.com/oauth/authorize?${params.toString()}`;

  return (
    <a href={cleverUrl}>
      <img
        src={images[variant]}
        width={207}
        height={45}
        alt="Sign up with Clever"
      />
    </a>
  );
}

export default CleverButton;
