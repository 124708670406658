function findOnly<T>(
  items: ReadonlyArray<T>,
  predicate: (item: T) => boolean,
): T {
  const matches = items.filter(predicate);
  if (matches.length === 0) {
    throw new Error("No matching elements");
  }
  if (matches.length > 1) {
    throw new Error("Multiple matching elements");
  }
  return matches[0];
}

export { findOnly };
