import rawChapters from "./chapters.json";

type TopicDescription = {
  readonly code: string;
  readonly content: string;
};

type TopicQuestion = {
  readonly apiActivityId?: string;
  readonly title: string;
  readonly description: string;
  readonly imagePath: string;
  readonly numberOfProblems?: number;
};

type Topic = {
  readonly name: string;
  readonly descriptions: readonly TopicDescription[];
  readonly questions: readonly TopicQuestion[];
};

type ReviewQuestion = {
  readonly question: string;
  readonly options?: ReadonlyArray<string>;
  readonly table?: ReadonlyArray<ReadonlyArray<string | number>>;
};

type ChapterData = {
  readonly description: ReadonlyArray<string>;
  readonly topics: ReadonlyArray<Topic>;
  readonly reviewQuestions: ReadonlyArray<ReviewQuestion>;
};

const chaptersData: ReadonlyArray<ChapterData> = rawChapters;

export type { ChapterData };
export default chaptersData;
